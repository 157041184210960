export default [
    {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/views/calendar/Calendar"),
        meta: {
            pageTitle: "Calendar",
            resource: "Profile",
            gate: "Profile",
            breadcrumb: [
                {
                    text: "Profile",
                    active: true
                }
            ]
        }
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/AccountSettings"),
        meta: {
            pageTitle: "Profile",
            resource: "Profile",
            gate: "Profile",
            breadcrumb: [
                {
                    text: "Profile",
                    active: true
                }
            ]
        }
    },
    {
        path: "/employees",
        name: "employees",
        component: () => import("@/views/employees/list/EmployeeList"),
        meta: {
            pageTitle: "Employee List",
            resource: "Employee",
            gate: "employee_access",
            breadcrumb: [
                {
                    text: "List",
                    active: true
                }
            ]
        }
    },
    {
        path: "/members",
        name: "members",
        component: () => import("@/views/users/list/UsersList"),
        meta: {
            pageTitle: "Members List",
            resource: "Member",
            gate: "employee_access",
            breadcrumb: [
                {
                    text: "List",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/employee",
        name: "add-employee",
        component: () => import("@/views/employees/add/EmployeeAdd"),
        meta: {
            pageTitle: "Employee Add",
            resource: "Employee",
            gate: "employee_create",
            breadcrumb: [
                {
                    text: "Employees List",
                    to: "/employees"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/employee/:id",
        name: "edit-employee",
        component: () => import("@/views/employees/edit/EmployeeEdit"),
        meta: {
            pageTitle: "Employee Edit",
            resource: "Employee",
            gate: "employee_edit",
            breadcrumb: [
                {
                    text: "Employees List",
                    to: "/employees"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/employee/:id",
        name: "view-employee",
        component: () => import("@/views/employees/view/EmployeeView"),
        meta: {
            pageTitle: "Employee View",
            resource: "Employee",
            gate: "employee_show",
            breadcrumb: [
                {
                    text: "Employees List",
                    to: "/employees"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/member",
        name: "add-member",
        component: () => import("@/views/users/add/UserAdd"),
        meta: {
            pageTitle: "Add Member",
            resource: "Member",
            gate: "employee_create",
            breadcrumb: [
                {
                    text: "Members List",
                    to: "/members"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/member/:id",
        name: "edit-member",
        component: () => import("@/views/users/edit/UserEdit"),
        meta: {
            pageTitle: "Member Edit",
            resource: "Member",
            gate: "employee_edit",
            breadcrumb: [
                {
                    text: "Member List",
                    to: "/members"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    /* checklist module */
    {
        path: "/manage/checklist",
        name: "manage-checklist",
        component: () =>
            import("@/views/employees/checklist/list/ChecklistList"),
        meta: {
            pageTitle: "CheckList Management",
            resource: "CheckList Management",
            gate: "checklist_access",
            breadcrumb: [
                {
                    text: "CheckList Management",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/checklist",
        name: "add-checklist",
        component: () => import("@/views/employees/checklist/add/ChecklistAdd"),
        meta: {
            pageTitle: "Checklist Add",
            resource: "CheckList Management",
            gate: "checklist_create",
            breadcrumb: [
                {
                    text: "Checklist",
                    to: "/manage/checklist"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/checklist/:id",
        name: "edit-checklist",
        component: () =>
            import("@/views/employees/checklist/edit/ChecklistEdit"),
        meta: {
            pageTitle: "Checklist Edit",
            resource: "CheckList Management",
            gate: "checklist_edit",
            breadcrumb: [
                {
                    text: "Checklist",
                    to: "/manage/checklist"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },

    {
        path: "/serve",
        name: "serve",
        meta: {
            gate: "Profile"
        },
        component: () => import("@/views/Serve")
    },

    {
        path: "/interview/:id/feedback",
        name: "feedback",
        meta: {
            gate: "Profile"
        },
        component: () => import("@/views/interviews/assigned/list/FeedBackAddForm")
    },

    {
        path: "/raise/nhr",
        name: "add-nhr",
        component: () => import("@/views/nhr/add/NHRAdd"),
        meta: {
            pageTitle: "Add New Hire Request",
            resource: "Hire Request",
            gate: "hire_request_create",
            breadcrumb: [
                {
                    text: "New Hire Request List",
                    to: "/all/nhr-list"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },

    {
        path: "/nhr/view/candidate/:candidateId/:nhrId",
        name: "nhr-view-candidate",
        component: () => import("@/views/nhr/candidate/view/CandidateView"),
        meta: {
            pageTitle: "View Candidate",
            gate: "candidate_show",
            resource: "Hire Request"
        }
    },

    /* Referral module */
    {
        path: "/referrals",
        name: "my-referrals",
        component: () => import("@/views/referrals/list/ReferralList"),
        meta: {
            pageTitle: "Referrals",
            resource: "Referral",
            gate: "referral_access",
            breadcrumb: [
                {
                    text: "Referrals",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/referral/:id",
        name: "view-referral",
        component: () => import("@/views/referrals/view/ReferralView"),
        meta: {
            pageTitle: "View Referral",
            resource: "Referral",
            gate: "referral_show",
            breadcrumb: [
                {
                    text: "Referrals",
                    to: "/referrals"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/candidates",
        name: "candidates",
        component: () => import("@/views/candidates/list/CandidateList"),
        meta: {
            pageTitle: "Candidates List",
            resource: "Candidate",
            gate: "candidate_access",
            breadcrumb: [
                {
                    text: "Candidates List",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/candidate",
        name: "add-candidate",
        component: () => import("@/views/candidates/add/CandidateAdd"),
        meta: {
            pageTitle: "Candidates Add",
            resource: "Candidate",
            gate: "candidate_create",
            breadcrumb: [
                {
                    text: "Candidates List",
                    to: "/candidates"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/candidate/:id",
        name: "edit-candidate",
        component: () => import("@/views/candidates/edit/CandidateEdit"),
        meta: {
            pageTitle: "Candidates Edit",
            resource: "Candidate",
            gate: "candidate_edit",
            breadcrumb: [
                {
                    text: "Candidates List",
                    to: "/candidates"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/candidate/:id",
        name: "view-candidate",
        component: () => import("@/views/candidates/view/CandidateView"),
        meta: {
            pageTitle: "Candidate View",
            resource: "Candidate",
            gate: "candidate_show",
            breadcrumb: [
                {
                    text: "Candidates List",
                    to: "/candidates"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    // Manage Candidate
    // Interview Module
    {
        path: "/all/interviews",
        name: "all-interviews",
        component: () => import("@/views/interviews/list/InterviewList"),
        meta: {
            pageTitle: "Interviews List",
            resource: "Interview",
            gate: "interview_access",
            breadcrumb: [
                {
                    text: "All Interviews",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/assigned-interview/:id",
        name: "view-assigned-interviews",
        component: () =>
            import("@/views/interviews/assigned/view/InterviewView"),
        meta: {
            pageTitle: "View Assigned Interview",
            resource: "Interview",
            gate: "interview_show",
            breadcrumb: [
                {
                    text: "All Assigned Interviews",
                    to: "/assigned/interviews"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/interview/:id",
        name: "view-interview",
        component: () => import("@/views/interviews/view/InterviewView"),
        meta: {
            pageTitle: "Interview View",
            resource: "Interview",
            gate: "interview_show",
            breadcrumb: [
                {
                    text: "All Interviews",
                    to: "/all/interviews"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    /* All NHR */
    {
        path: "/all/nhr-list",
        name: "all-nhr-list",
        component: () => import("@/views/nhr/allNhr/list/NHRList"),
        meta: {
            pageTitle: "New Hire Request List",
            resource: "Hire Request",
            gate: "interview_show",
            breadcrumb: [
                {
                    text: "NHR List",
                    active: true
                }
            ]
        }
    },
    {
        path: "/all/view/nhr/:id",
        name: "all-view-nhr",
        component: () => import("@/views/nhr/allNhr/view/NHRView"),
        meta: {
            pageTitle: "New Hire Request View",
            resource: "Hire Request",
            gate: "hire_request_show",
            breadcrumb: [
                {
                    text: "New Hire Request List",
                    to: "/all/nhr-list"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/all/edit/nhr/:id",
        name: "all-edit-nhr",
        component: () => import("@/views/nhr/allNhr/edit/NHREdit"),
        meta: {
            pageTitle: "Hire Request Edit",
            resource: "Hire Request",
            gate: "hire_request_edit",
            breadcrumb: [
                {
                    text: "New Hire Request List",
                    to: "/all/nhr-list"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/all/nhr/view/candidate/:candidateId/:nhrId",
        name: "all-nhr-view-candidate",
        component: () =>
            import("@/views/nhr/allNhr/candidate/view/CandidateView"),
        meta: {
            pageTitle: "View Candidate",
            gate: "candidate_show",
            resource: "Hire Request"
        }
    },
    {
        path: "/settings",
        name: "company-settings",
        component: () =>
            import("@/views/settings/company-settings/CompanySettingsEdit"),
        meta: {
            pageTitle: "Company Settings",
            gates: ["Zircly Admin", "settings_access"],
            breadcrumb: [
                {
                    text: "Company Settings",
                    to: "/settings"
                }
            ]
        }
    },
    {
        path: "/role-permission",
        name: "role-permission",
        component: () =>
            import("@/views/role-permission/list/rolePermissionList"),
        meta: {
            pageTitle: "Role & Permission",
            resource: "Company",
            gates: ["Zircly Admin", "roles_access"],
            breadcrumb: [
                {
                    text: "Role & Permission",
                    to: "/role-permission"
                }
            ]
        }
    },
    {
        path: "/settings/custom-fields",
        name: "custom-fields",
        component: () => import("@/views/custom-fields/list/CustomFieldList"),
        meta: {
            pageTitle: "Custom Fields",
            // resource: "Settings",
            gates: ["Zircly Admin", "custom_fields_access"],
            breadcrumb: [
                {
                    text: "Custom Fields",
                    active: true
                }
            ]
        }
    },
    {
        path: "/settings/email-subscription",
        name: "email-subscription",
        component: () => import("@/views/email-settings/EmailSettings.vue"),
        meta: {
            pageTitle: "Email Subscription",
            // resource: "Settings",
            gate: "email_notification_subscription_edit",
            breadcrumb: [
                {
                    text: "Email Subscription",
                    active: true
                }
            ]
        }
    },
    {
        // policy pages
        path: "/terms-and-conditions",
        name: "terms",
        component: () => import("@/views/policy-pages/Terms"),
        meta: {
            pageTitle: "Terms and conditions",
            resource: "Auth",
            gate: "Public",
            layout: "full"
        }
    },
    {
        // policy pages
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("@/views/policy-pages/PrivacyPolicy"),
        meta: {
            pageTitle: "Privacy Policy",
            resource: "Auth",
            gate: "Public",
            layout: "full"
        }
    },
    {
        //
        path: "/contact-us",
        name: "contact-us",
        component: () => import("@/views/pages/ContactUs"),
        meta: {
            pageTitle: "Contact Us",
            resource: "Auth",
            gate: "Public",
            layout: "full"
        }
    },
    {
        //
        path: "/about-us",
        name: "about-us",
        component: () => import("@/views/pages/AboutUs"),
        meta: {
            pageTitle: "About Us",
            resource: "Auth",
            gate: "Public",
            layout: "full"
        }
    },
    {
        //
        path: "/Zircly-Zoom-Integration-App-Documentation",
        name: "Zircly-Zoom-Integration-App-Documentation",
        component: () => import("@/views/pages/ZoomDocumentation"),
        meta: {
            pageTitle: "Zircly Zoom Integration App Documentation",
            resource: "Auth",
            gate: "Public",
            layout: "full"
        }
    },
    {
        //
        path: "/delete-my-account",
        name: "Delete My Zircly Account",
        component: () => import("@/views/pages/DeleteMyAccount"),
        meta: {
            pageTitle: "Delete My Zircly Account",
            resource: "Auth",
            gate: "Public",
            layout: "full"
        }
    },
    {
        path: "/join-zoom/:id",
        name: "join-zoom",
        component: () => import("@/views/interviews/assigned/view/JoinZoom"),
        meta: {
            gate: "Public"
        }
    },
    {
        path: "/jobs",
        name: "jobs",
        component: () => import("@/views/vacancies/list/VacancyList"),
        meta: {
            pageTitle: "Job Openings",
            resource: "Vacancy",
            gate: "vacancy_access",
            breadcrumb: [
                {
                    text: "Job Openings",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/job",
        name: "add-job",
        component: () => import("@/views/vacancies/add/VacancyAdd"),
        meta: {
            pageTitle: "Job Add",
            resource: "Vacancy",
            gate: "vacancy_create",
            breadcrumb: [
                {
                    text: "Job Openings",
                    to: "/jobs"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/job/:id",
        name: "edit-job",
        component: () => import("@/views/vacancies/edit/VacancyEdit"),
        meta: {
            pageTitle: "Job Edit",
            resource: "Vacancy",
            gate: "vacancy_edit",
            breadcrumb: [
                {
                    text: "Job Openings",
                    to: "/jobs"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/job/:id",
        name: "view-job",
        component: () => import("@/views/vacancies/view/VacancyView"),
        meta: {
            pageTitle: "Job View",
            resource: "Vacancy",
            gate: "vacancy_show",
            breadcrumb: [
                {
                    text: "Job Openings",
                    to: "/jobs"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/update-profile-requests",
        name: "update-profile-requests",
        component: () =>
            import(
                "@/views/update-profile-requests/list/UpdateProfileRequestList"
            ),
        meta: {
            pageTitle: "Update Profile Requests List",
            resource: "UpdateProfileRequest",
            gate: "update_profile_request_access",
            breadcrumb: [
                {
                    text: "Update Profile Requests List",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/update-profile-request/:id",
        name: "edit-update-profile-request",
        component: () =>
            import(
                "@/views/update-profile-requests/edit/UpdateProfileRequestEdit"
            ),
        meta: {
            pageTitle: "Update Profile Request Edit",
            resource: "UpdateProfileRequest",
            gate: "update_profile_request_edit",
            breadcrumb: [
                {
                    text: "Update Profile Request List",
                    to: "/update-profile-requests"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/update-profile-request/:id",
        name: "view-update-profile-request",
        component: () =>
            import(
                "@/views/update-profile-requests/view/UpdateProfileRequestView"
            ),
        meta: {
            pageTitle: "Update Profile Request View",
            resource: "UpdateProfileRequest",
            gate: "update_profile_request_show",
            breadcrumb: [
                {
                    text: "Update Profile Requests List",
                    to: "/update-profile-requests"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/shifts",
        name: "shifts",
        component: () => import("@/views/shifts/list/ShiftList"),
        meta: {
            pageTitle: "Shifts",
            resource: "Shift",
            breadcrumb: [
                {
                    text: "Shifts",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/shift",
        name: "add-shift",
        component: () => import("@/views/shifts/add/ShiftAdd"),
        meta: {
            pageTitle: "Shift Add",
            resource: "Shift",
            breadcrumb: [
                {
                    text: "Shifts",
                    to: "/shifts"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/shift/:id",
        name: "edit-shift",
        component: () => import("@/views/shifts/edit/ShiftEdit"),
        meta: {
            pageTitle: "Shift Edit",
            resource: "Shift",
            breadcrumb: [
                {
                    text: "Shifts",
                    to: "/shifts"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/shift/:id",
        name: "view-shift",
        component: () => import("@/views/shifts/view/ShiftView"),
        meta: {
            pageTitle: "Shift View",
            resource: "Shift",
            breadcrumb: [
                {
                    text: "Shifts",
                    to: "/shifts"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/holidays",
        name: "holidays",
        component: () => import("@/views/holidays/list/HolidayList"),
        meta: {
            pageTitle: "Holidays Manager",
            resource: "Holidays",
            breadcrumb: [
                {
                    text: "Holidays Manager",
                    active: true
                }
            ]
        }
    },
    {
        path: "/holiday-calendar",
        name: "holiday-calendar",
        component: () => import("@/views/holidays/view/HolidayCalendar"),
        meta: {
            pageTitle: "Holiday Calendar",
            resource: "Holiday Calendar",
            breadcrumb: [
                {
                    text: "Holidays Calendar",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/holiday",
        name: "add-holiday",
        component: () => import("@/views/holidays/add/HolidayAdd"),
        meta: {
            pageTitle: "Holiday Add",
            resource: "Holidays",
            breadcrumb: [
                {
                    text: "Holidays Manager",
                    to: "/holidays"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/holiday/:id",
        name: "edit-holiday",
        component: () => import("@/views/holidays/edit/HolidayEdit"),
        meta: {
            pageTitle: "Holiday Edit",
            resource: "Holidays",
            breadcrumb: [
                {
                    text: "Holidays",
                    to: "/holidays"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/holiday/:id",
        name: "view-holiday",
        component: () => import("@/views/holidays/view/HolidayView"),
        meta: {
            pageTitle: "Holiday View",
            resource: "Holidays",
            breadcrumb: [
                {
                    text: "Holidays",
                    to: "/holidays"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/attendance/attendance-info",
        name: "attendance-info",
        component: () =>
            import("@/views/attendance/attendance-info/AttendanceInfo.vue"),
        meta: {
            pageTitle: "Attendance Info",
            breadcrumb: [
                {
                    text: "Attendance",
                    active: true
                }
            ]
        }
    },
    {
        path: "/leaves",
        name: "leaves",
        component: () => import("@/views/leaves/list/LeaveList"),
        meta: {
            pageTitle: "Leaves List",
            resource: "Leaves",
            breadcrumb: [
                {
                    text: "Leaves",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/leave",
        name: "add-leave",
        component: () => import("@/views/leaves/add/LeaveAdd"),
        meta: {
            pageTitle: "Leave Add",
            resource: "Leaves",
            breadcrumb: [
                {
                    text: "Leaves",
                    to: "/leaves"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/leave/:id",
        name: "edit-leave",
        component: () => import("@/views/leaves/edit/LeaveEdit"),
        meta: {
            pageTitle: "Leave Edit",
            resource: "Leaves",
            breadcrumb: [
                {
                    text: "Leaves",
                    to: "/leaves"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/leave/:id",
        name: "view-leave",
        component: () => import("@/views/leaves/view/LeaveView"),
        meta: {
            pageTitle: "Leave View",
            resource: "Leaves",
            breadcrumb: [
                {
                    text: "Leaves",
                    to: "/leaves"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/leave-applications",
        name: "leave-applications",
        component: () =>
            import("@/views/leave_applications/list/LeaveApplicationList"),
        meta: {
            pageTitle: "Leave Applications Manager",
            resource: "Leave Applications",
            breadcrumb: [
                {
                    text: "Leave Applications Manager",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/leave-application",
        name: "add-leave-application",
        component: () =>
            import("@/views/leave_applications/add/LeaveApplicationAdd"),
        meta: {
            pageTitle: "Apply for Leave",
            resource: "Leave Applications",
            breadcrumb: [
                {
                    text: "Leave Applications",
                    to: "/leave-applications"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/leave-application/:id",
        name: "edit-leave-application",
        component: () =>
            import("@/views/leave_applications/edit/LeaveApplicationEdit"),
        meta: {
            pageTitle: "Edit Leave Application",
            resource: "Leave Applications",
            breadcrumb: [
                {
                    text: "Leave Applications",
                    to: "/leave-applications"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/leave-application/:id",
        name: "view-leave-application",
        component: () =>
            import("@/views/leave_applications/view/LeaveApplicationView"),
        meta: {
            pageTitle: "Leave Application View",
            resource: "Leave Applications",
            breadcrumb: [
                {
                    text: "Leave Applications",
                    to: "/leave-applications"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/employee-leaves",
        name: "employee-leaves",
        component: () =>
            import("@/views/employee_leaves/list/EmployeeLeaveList"),
        meta: {
            pageTitle: "Employee Leaves Balance",
            resource: "Employee Leaves Balance",
            breadcrumb: [
                {
                    text: "Leaves",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/employee-leaves",
        name: "add-employee-leave",
        component: () => import("@/views/employee_leaves/add/EmployeeLeaveAdd"),
        meta: {
            pageTitle: "Employee Leave Add",
            resource: "Employee Leaves",
            breadcrumb: [
                {
                    text: "Employee Leave List",
                    to: "/employee-leaves"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/employee-leaves/:id",
        name: "edit-employee-leave",
        component: () =>
            import("@/views/employee_leaves/edit/EmployeeLeaveEdit"),
        meta: {
            pageTitle: "Employee Leave Edit",
            resource: "Employee Leaves",
            breadcrumb: [
                {
                    text: "Employee Leave List",
                    to: "/employee-leaves"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/employee-leaves/:id",
        name: "view-employee-leave",
        component: () =>
            import("@/views/employee_leaves/view/EmployeeLeaveView"),
        meta: {
            pageTitle: "Employee Leave View",
            resource: "Employee Leaves",
            breadcrumb: [
                {
                    text: "Employee Leave List",
                    to: "/employee-leaves"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/attendance-muster",
        name: "attendance-muster",
        component: () =>
            import("@/views/attendance_musters/list/AttendanceMusterList"),
        meta: {
            pageTitle: "Attendance Muster List",
            resource: "Attendance Muster",
            breadcrumb: [
                {
                    text: "Attendance Muster",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/attendance-muster/:id",
        name: "view-attendance-muster",
        component: () =>
            import("@/views/attendance_musters/view/AttendanceMusterView"),
        meta: {
            pageTitle: "Attendance Muster View",
            resource: "Attendance Muster",
            breadcrumb: [
                {
                    text: "Attendance Muster",
                    to: "/attendance-muster"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/compensatory-off-applications",
        name: "compensatory-off-applications",
        component: () =>
            import(
                "@/views/compensatory_off_applications/list/CompensatoryOffApplicationList"
            ),
        meta: {
            pageTitle: "Comp Off Applications",
            resource: "Comp Off Applications",
            breadcrumb: [
                {
                    text: "Comp Off Applications",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/compensatory-off-application",
        name: "add-compensatory-off-application",
        component: () =>
            import(
                "@/views/compensatory_off_applications/add/CompensatoryOffApplicationAdd"
            ),
        meta: {
            pageTitle: "Apply for Comp Off",
            resource: "Comp Off Applications",
            breadcrumb: [
                {
                    text: "Comp Off Applications",
                    to: "/compensatory-off-applications"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/compensatory-off-application/:id",
        name: "edit-compensatory-off-application",
        component: () =>
            import(
                "@/views/compensatory_off_applications/edit/CompensatoryOffApplicationEdit"
            ),
        meta: {
            pageTitle: "Edit Comp Off Application",
            resource: "Comp Off Applications",
            breadcrumb: [
                {
                    text: "Comp Off Applications",
                    to: "/compensatory-off-applications"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/compensatory-off-application/:id",
        name: "view-compensatory-off-application",
        component: () =>
            import(
                "@/views/compensatory_off_applications/view/CompensatoryOffApplicationView"
            ),
        meta: {
            pageTitle: "Comp Off Application View",
            resource: "Comp Off Applications",
            breadcrumb: [
                {
                    text: "Comp Off Applications",
                    to: "/compensatory-off-applications"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/regularize-applications",
        name: "regularize-applications",
        component: () =>
            import("@/views/regularizations/list/RegularizationList"),
        meta: {
            pageTitle: "Regularize Applications",
            resource: "Regularize Applications",
            breadcrumb: [
                {
                    text: "Regularize Applications Manager",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/regularize-application",
        name: "add-regularize-application",
        component: () =>
            import("@/views/regularizations/add/RegularizationAdd"),
        meta: {
            pageTitle: "Apply for Regularization",
            resource: "Regularize Applications",
            breadcrumb: [
                {
                    text: "Regularize Applications Manager",
                    to: "/regularize-applications"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/regularize-application/:id",
        name: "edit-regularize-application",
        component: () =>
            import("@/views/regularizations/edit/RegularizationEdit"),
        meta: {
            pageTitle: "Edit Regularization",
            resource: "Regularize Applications",
            breadcrumb: [
                {
                    text: "Regularize Applications Manager",
                    to: "/regularize-applications"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/regularize-application/:id",
        name: "view-regularize-application",
        component: () =>
            import("@/views/regularizations/view/RegularizationView"),
        meta: {
            pageTitle: "Regularization View",
            resource: "Regularize Applications",
            breadcrumb: [
                {
                    text: "Regularize Applications Manager",
                    to: "/regularize-applications"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/email-notifications",
        name: "email-notifications",
        component: () =>
            import("@/views/email-notifications/list/EmailNotificationList"),
        meta: {
            pageTitle: "Email Notification",
            resource: "Email Notification",
            breadcrumb: [
                {
                    text: "Email Notification",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/email-notification",
        name: "add-email-notification",
        component: () =>
            import("@/views/email-notifications/add/EmailNotificationAdd"),
        meta: {
            pageTitle: "Add Email Notification",
            resource: "Email Notification",
            breadcrumb: [
                {
                    text: "Email Notification",
                    to: "/email-notifications"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/email-notification/:id",
        name: "edit-email-notification",
        component: () =>
            import("@/views/email-notifications/edit/EmailNotificationEdit"),
        meta: {
            pageTitle: "Edit Email Notification",
            resource: "Email Notification",
            breadcrumb: [
                {
                    text: "Email Notification",
                    to: "/email-notifications"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/email-notification/:id",
        name: "view-email-notification",
        component: () =>
            import("@/views/email-notifications/view/EmailNotificationView"),
        meta: {
            pageTitle: "Email Notification View",
            resource: "Email Notification",
            breadcrumb: [
                {
                    text: "Email Notification",
                    to: "/email-notifications"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/teams",
        name: "teams",
        component: () => import("@/views/teams/list/TeamList"),
        meta: {
            pageTitle: "Teams List",
            resource: "Teams",
            breadcrumb: [
                {
                    text: "Teams",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/team",
        name: "add-team",
        component: () => import("@/views/teams/add/TeamAdd"),
        meta: {
            pageTitle: "Team Add",
            resource: "Teams",
            breadcrumb: [
                {
                    text: "Teams",
                    to: "/teams"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/team/:id",
        name: "edit-team",
        component: () => import("@/views/teams/edit/TeamEdit"),
        meta: {
            pageTitle: "Team Edit",
            resource: "Teams",
            breadcrumb: [
                {
                    text: "Teams",
                    to: "/teams"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/team/:id",
        name: "view-team",
        component: () => import("@/views/teams/view/TeamView"),
        meta: {
            pageTitle: "Team View",
            resource: "Teams",
            breadcrumb: [
                {
                    text: "Teams",
                    to: "/teams"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/people",
        name: "people",
        component: () => import("@/views/people/People"),
        meta: {
            pageTitle: "People",
            resource: "People",
            breadcrumb: [
                {
                    text: "People",
                    active: true
                }
            ]
        }
    },
    {
        path: "/company-tree",
        name: "company-tree",
        component: () => import("@/views/company-trees/list/CompanyTreeList"),
        meta: {
            // pageTitle: "Company Tree List",
            resource: "Company Tree"
            // breadcrumb: [
            //     {
            //         text: "Company Tree",
            //         active: true
            //     }
            // ]
        }
    },
    {
        path: "/manage/company-tree",
        name: "company-tree-manager",
        component: () =>
            import("@/views/company-trees/manage/CompanyTreeManage"),
        meta: {
            pageTitle: "Company Tree Manager",
            resource: "Company Tree Manager",
            breadcrumb: [
                {
                    text: "Company Tree",
                    to: "/company-tree"
                },
                {
                    text: "Manager",
                    active: true
                }
            ]
        }
    },
    {
        path: "/credentials/api-keys",
        name: "api-keys",
        component: () => import("@/views/api-keys/list/ApiKeyList"),
        meta: {
            pageTitle: "API Keys",
            resource: "credentials",
            breadcrumb: [
                {
                    text: "API Keys",
                    active: true
                }
            ]
        }
    },
    {
        path: "/voice-call-logs",
        name: "voice-call-logs",
        component: () =>
            import("@/views/voice-call-logs/list/VoiceCallLogList"),
        meta: {
            resource: "Voice Call Log",
            pageTitle: "Voice Call Log",
            breadcrumb: [
                {
                    text: "Voice Call Log",
                    active: true
                }
            ]
        }
    },
    {
        path: "/login-logs",
        name: "login-logs",
        component: () =>
            import("@/views/login-logs/list/LoginLogList"),
        meta: {
            resource: "Login Logs",
            pageTitle: "Login Logs",
            breadcrumb: [
                {
                    text: "Login Logs",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/voice-call-log/:id",
        name: "view-voice-call-log",
        component: () =>
            import("@/views/voice-call-logs/view/VoiceCallLogView"),
        meta: {
            pageTitle: "Voice Call Log View",
            resource: "Voice Call Log",
            breadcrumb: [
                {
                    text: "Voice Call Log",
                    to: "/voice-call-logs"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/settings/index",
        name: "settings",
        component: () => import("@/views/settings/Settings.vue"),
        meta: {
            pageTitle: "Settings",
            resource: "Settings",
            breadcrumb: [
                {
                    text: "Settings",
                    active: true
                }
            ]
        }
    },
    {
        path: "/okrs",
        name: "okrs",
        component: () => import("@/views/okrs/list/OkrList"),
        meta: {
            pageTitle: "OKRs List",
            resource: "OLD OKR",
            breadcrumb: [
                {
                    text: "OKRs",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/okr",
        name: "add-okr",
        component: () => import("@/views/okrs/add/OkrAdd"),
        meta: {
            pageTitle: "OKR Add",
            resource: "OKR",
            breadcrumb: [
                {
                    text: "OKRs",
                    to: "/okrs"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/okr/:id",
        name: "edit-okr",
        component: () => import("@/views/okrs/edit/OkrEdit"),
        meta: {
            pageTitle: "OKR Edit",
            resource: "OKR",
            breadcrumb: [
                {
                    text: "OKRs",
                    to: "/okrs"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/okr/:id",
        name: "view-okr",
        component: () => import("@/views/okrs/view/OkrView"),
        meta: {
            pageTitle: "OKR View",
            resource: "OKR",
            breadcrumb: [
                {
                    text: "OKRs",
                    to: "/okrs"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },

    {
        path: "/list/my-okrs",
        name: "my-okrs",
        component: () => import("@/views/new-okr/MyOKR"),
        meta: {
            pageTitle: "My OKRs",
            resource: "My OKRs",
            breadcrumb: [
                {
                    text: "My OKRs",
                    active: true
                }
            ]
        }
    },
    {
        path: "/list/all-okrs",
        name: "all-okrs",
        component: () => import("@/views/new-okr/MyOKR"),
        meta: {
            pageTitle: "All OKRs",
            resource: "All OKRs",
            breadcrumb: [
                {
                    text: "All OKRs",
                    active: true
                }
            ]
        }
    },
    {
        path: "/tree-okr",
        name: "tree-okr",
        component: () => import("@/views/new-okr/org-tree/tree.vue"),
        meta: {
            resource: "new okr tree",
            breadcrumb: [
                {
                    text: "new okr tree",
                    active: true
                }
            ]
        }
    },
    {
        path: "/tree",
        name: "tree",
        component: () => import("@/views/new-okr/tree.vue"),
        meta: {
            resource: "new okr tree",
            breadcrumb: [
                {
                    text: "new okr tree",
                    active: true
                }
            ]
        }
    },
    {
        path: "/list/review-okrs",
        name: "review-okrs",
        component: () => import("@/views/new-okr/MyOKR"),
        meta: {
            pageTitle: "Review OKRs",
            resource: "Review OKRs",
            breadcrumb: [
                {
                    text: "Review OKRs",
                    active: true
                }
            ]
        }
    },
    {
        path: "/create-okr/:type",
        name: "create-okr",
        component: () => import("@/views/new-okr/add/CreateNewOkr"),
        meta: {
            pageTitle: "Create New OKR",
            resource: "Create OKR",
            breadcrumb: [
                {
                    text: "My OKRs",
                    to: "/list/my-okrs"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/review-okr/:id/:year/:quarter",
        name: "review-okr",
        component: () => import("@/views/new-okr/ReviewOKR.vue"),
        meta: {
            pageTitle: "Review OKRs",
            resource: "Review OKRs",
        },
    },
    // {
    //     path: "/create-okr/team-okr",
    //     name: "create-okr",
    //     component: () => import("@/views/new-okr/add/CreateNewOkr"),
    //     meta: {
    //         pageTitle: "Create Team OKR",
    //         resource: "OKR",
    //         breadcrumb: [
    //             {
    //                 text: "OKRs",
    //                 to: "/okr"
    //             },
    //             {
    //                 text: "Add",
    //                 active: true
    //             }
    //         ]
    //     }
    // },
    // {
    //     path: "/create-okr/organisation-okr",
    //     name: "create-okr",
    //     component: () => import("@/views/new-okr/add/CreateNewOkr"),
    //     meta: {
    //         pageTitle: "Create Organisation OKR",
    //         resource: "OKR",
    //         breadcrumb: [
    //             {
    //                 text: "OKRs",
    //                 to: "/okr"
    //             },
    //             {
    //                 text: "Add",
    //                 active: true
    //             }
    //         ]
    //     }
    // },
    {
        path: "/support-categories",
        name: "support-categories",
        component: () =>
            import("@/views/support-categories/list/SupportCategoryList"),
        meta: {
            pageTitle: "Support Categories List",
            resource: "Support-categories",
            breadcrumb: [
                {
                    text: "Support Categories",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/support-category",
        name: "add-support-category",
        component: () =>
            import("@/views/support-categories/add/SupportCategoryAdd"),
        meta: {
            pageTitle: "Support Category Add",
            resource: "Support-categories",
            breadcrumb: [
                {
                    text: "Support Categories",
                    to: "/support-categories"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/support-category/:id",
        name: "edit-support-category",
        component: () =>
            import("@/views/support-categories/edit/SupportCategoryEdit"),
        meta: {
            pageTitle: "Support Category Edit",
            resource: "Support-categories",
            breadcrumb: [
                {
                    text: "Support Categories",
                    to: "/support-categories"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/support-category/:id",
        name: "view-support-category",
        component: () =>
            import("@/views/support-categories/view/SupportCategoryView"),
        meta: {
            pageTitle: "Support Category View",
            resource: "Support-categories",
            breadcrumb: [
                {
                    text: "Support Categories",
                    to: "/support-categories"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },

    {
        path: "/tickets",
        name: "tickets",
        component: () => import("@/views/tickets/list/TicketList"),
        meta: {
            pageTitle: "Support Tickets List",
            resource: "Tickets",
            breadcrumb: [
                {
                    text: "Support tickets",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/support-ticket",
        name: "add-ticket",
        component: () => import("@/views/tickets/add/TicketAdd"),
        meta: {
            pageTitle: "Support Ticket Add",
            resource: "Tickets",
            breadcrumb: [
                {
                    text: "Support tickets",
                    to: "/tickets"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/support-ticket/:id",
        name: "edit-ticket",
        component: () => import("@/views/tickets/edit/TicketEdit"),
        meta: {
            pageTitle: "Support Ticket Edit",
            resource: "Tickets",
            breadcrumb: [
                {
                    text: "Support tickets",
                    to: "/tickets"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/support-ticket/:id",
        name: "view-ticket",
        component: () => import("@/views/tickets/view/TicketView"),
        meta: {
            pageTitle: "Support Ticket View",
            resource: "Tickets",
            breadcrumb: [
                {
                    text: "Support tickets",
                    to: "/tickets"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/karma-programs",
        name: "karma-programs",
        component: () => import("@/views/karma-programs/list/KarmaProgramList"),
        meta: {
            pageTitle: "Karma Programs List",
            resource: "Karma Program",
            breadcrumb: [
                {
                    text: "Karma Program",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/karma-program",
        name: "add-karma-program",
        component: () => import("@/views/karma-programs/add/KarmaProgramAdd"),
        meta: {
            pageTitle: "Karma Program Add",
            resource: "Karma Program",
            breadcrumb: [
                {
                    text: "Karma Programs",
                    to: "/karma-programs"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/karma-program/:id",
        name: "edit-karma-program",
        component: () => import("@/views/karma-programs/edit/KarmaProgramEdit"),
        meta: {
            pageTitle: "Karma Program Edit",
            resource: "Karma Program",
            breadcrumb: [
                {
                    text: "Karma Programs",
                    to: "/karma-programs"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/karma-program/:id",
        name: "view-karma-program",
        component: () => import("@/views/karma-programs/view/KarmaProgramView"),
        meta: {
            pageTitle: "Karma Program View",
            resource: "Karma Program",
            breadcrumb: [
                {
                    text: "Karma Programs",
                    to: "/karma-programs"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/karma-leaderboard",
        name: "karma-leaderboard",
        component: () => import("@/views/karma-leaderboard/KarmaLeaderboard"),
        meta: {
            pageTitle: "Karma Leaderboard",
            resource: "Karma Leaderboard",
            breadcrumb: [
                {
                    text: "Zircly Insider Program",
                    active: true
                }
            ]
        }
    },
    {
        path: "/zircly-insider-program/view/karma-history/:id",
        name: "karma-history",
        component: () =>
            import("@/views/karma-leaderboard/KarmaHistory"),
        meta: {
            pageTitle: "Karma History",
            resource: "Karma Leaderboard",
            breadcrumb: [
                {
                    text: "Karma Leaderboard",
                    to: "/karma-leaderboard"
                },
                {
                    text: "Karma History",
                    active: true
                }
            ]
        }
    },
    {
        path: "/zircly-insider-program/karma-catalogs",
        name: "karma-catalogs",
        component: () =>
            import("@/views/karma-catalogue/KarmaCatalogue"),
        meta: {
            pageTitle: "Karma Catalogs",
            resource: "Zircly Insider Program",
            breadcrumb: [
                {
                    text: "Karma Leaderboard",
                    to: "/karma-leaderboard"
                },
                {
                    text: "Karma Catalogs",
                    active: true
                }
            ]
        }
    },
    {
        path: "/faq",
        name: "faqs",
        component: () => import("@/views/faqs/Faq"),
        meta: {
            pageTitle: "Faq",
            resource: "Faq",
            breadcrumb: [
                {
                    text: "Faq",
                    active: true
                }
            ]
        }
    },
    {
        path: "/notes",
        name: "my-notes",
        component: () => import("@/views/notes/NotesList"),
        meta: {
            pageTitle: "My Notes",
            resource: "Notes",
            breadcrumb: [
                {
                    text: "Notes",
                    active: true
                }
            ]
        }
    },
    {
        path: "/direct-applications",
        name: "direct-applications",
        component: () =>
            import("@/views/candidates/direct_applications/CandidateList"),
        meta: {
            pageTitle: "Direct Applications",
            resource: "Direct Applications",
            breadcrumb: [
                {
                    text: "Direct Applications",
                    active: true
                }
            ]
        }
    },
    {
        path: "/meetings",
        name: "meetings",
        component: () => import("@/views/meetings/list/MeetingList"),
        meta: {
            pageTitle: "Meetings List",
            resource: "Meetings",
            breadcrumb: [
                {
                    text: "Meetings",
                    active: true
                }
            ]
        }
    },
    {
        path: "/exports",
        name: "exports",
        component: () => import("@/views/exports/list/ExportList"),
        meta: {
            pageTitle: "Exports List",
            resource: "Exports",
            breadcrumb: [
                {
                    text: "Exports",
                    active: true
                }
            ]
        }
    },
    {
        path: "/imports",
        name: "imports",
        component: () => import("@/views/imports/list/ImportList"),
        meta: {
            pageTitle: "Import List",
            resource: "Import",
            breadcrumb: [
                {
                    text: "Import",
                    active: true
                }
            ]
        }
    },
    {
        path: "/quick-links",
        name: "quick-links",
        component: () => import("@/views/quick-links/List.vue"),
        meta: {
            pageTitle: "Quick Links",
            resource: "Quick Links",
            breadcrumb: [
                {
                    text: "Quick Links",
                    active: true
                }
            ]
        }
    },
    //New Release Note Pages ( List & Detail)
    {
        path: "/release-note",
        name: "release-note",
        component: () => import("@/views/release-note/List.vue"),
        meta: {
            pageTitle: "Quick Links",
            resource: "Quick Links",
            breadcrumb: [
                {
                    text: "Quick Links",
                    active: true
                }
            ]
        }
    },
    {
        path: "/release-detail",
        name: "release-detail",
        component: () => import("@/views/release-note/Grid.vue"),
        meta: {
            pageTitle: "Release Detail",
            resource: "Release Detail",
            breadcrumb: [
                {
                    text: "Release Detail",
                    active: true
                }
            ]
        }
    },
    {
        path: "/secret-santa",
        name: "secret-santa",
        component: () => import("@/views/secret-santa/landing.vue"),
        meta: {
            pageTitle: "Secret Santa Program",
            resource: "Secret Santa",
            breadcrumb: [
                {
                    text: "Secret Santa",
                    active: true
                }
            ]
        }
    },
    {
        path: "/map-view",
        name: "map-view",
        component: () => import("@/views/map-view/EmployeeMap.vue"),
        meta: {
            pageTitle: "Employees Map View",
            resource: "Map View"
        }
    },
    {
        path: "/karma-categories",
        name: "karma-categories",
        component: () => import("@/views/karma-categories/list/KarmaCategoryList"),
        meta: {
            pageTitle: "Karma Category List",
            resource: "Karma Category",
            breadcrumb: [
                {
                    text: "Karma Category",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/karma-category/:id",
        name: "view-karma-category",
        component: () => import("@/views/karma-categories/view/KarmaCategoryView"),
        meta: {
            pageTitle: "Karma Category View",
            resource: "Karma Category",
            breadcrumb: [
                {
                    text: "Karma Category",
                    to: "/karma-categories"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/karma-category",
        name: "add-karma-category",
        component: () => import("@/views/karma-categories/add/KarmaCategoryAdd"),
        meta: {
            pageTitle: "Karma Category Add",
            resource: "Karma Category",
            breadcrumb: [
                {
                    text: "Karma Category",
                    to: "/karma-categories"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/karma-category/:id",
        name: "edit-karma-category",
        component: () => import("@/views/karma-categories/edit/KarmaCategoryEdit"),
        meta: {
            pageTitle: "Karma Category Edit",
            resource: "Karma Category",
            breadcrumb: [
                {
                    text: "Karma Category",
                    to: "/karma-categories"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/karma-catalogs",
        name: "list-karma-catalogs",
        component: () => import("@/views/karma-catalogue/list/KarmaCatalogueList"),
        meta: {
            pageTitle: "Karma Catalog List",
            resource: "Karma Catalog",
            breadcrumb: [
                {
                    text: "Karma Catalog",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/karma-catalog/:id",
        name: "view-karma-catalog",
        component: () => import("@/views/karma-catalogue/view/KarmaCatalogueView"),
        meta: {
            pageTitle: "Karma Catalog View",
            resource: "Karma Catalog",
            breadcrumb: [
                {
                    text: "Karma Catalog",
                    to: "/karma-catalogs"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/karma-catalog",
        name: "add-karma-catalog",
        component: () => import("@/views/karma-catalogue/add/KarmaCatalogueAdd"),
        meta: {
            pageTitle: "Karma Catalog Add",
            resource: "Karma Catalog",
            breadcrumb: [
                {
                    text: "Karma Catalog",
                    to: "/karma-catalogs"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/karma-catalog/:id",
        name: "edit-karma-catalog",
        component: () => import("@/views/karma-catalogue/edit/KarmaCatalogueEdit"),
        meta: {
            pageTitle: "Karma Catalog Edit",
            resource: "Karma Catalog",
            breadcrumb: [
                {
                    text: "Karma Catalog",
                    to: "/karma-catalogs"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/karma-requests",
        name: "karma-requests",
        component: () => import("@/views/karma-requests/list/KarmaRequestList"),
        meta: {
            pageTitle: "Karma Requests List",
            resource: "Karma Request",
            breadcrumb: [
                {
                    text: "karma-requests",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/karma-request",
        name: "add-karma-request",
        component: () => import("@/views/karma-requests/add/KarmaRequestAdd"),
        meta: {
            pageTitle: "Karma Request Add",
            resource: "Karma Request",
            breadcrumb: [
                {
                    text: "karma-requests",
                    to: "/karma-requests"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/karma-request/:id",
        name: "edit-karma-request",
        component: () => import("@/views/karma-requests/edit/KarmaRequestEdit"),
        meta: {
            pageTitle: "Karma Request Edit",
            resource: "Karma Request",
            breadcrumb: [
                {
                    text: "karma-requests",
                    to: "/karma-requests"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/karma-request/:id",
        name: "view-karma-request",
        component: () => import("@/views/karma-requests/view/KarmaRequestView"),
        meta: {
            pageTitle: "Karma Request View",
            resource: "Karma Request",
            breadcrumb: [
                {
                    text: "karma-requests",
                    to: "/karma-requests"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/faq-list",
        name: "list-faq",
        component: () => import("@/views/faqs/list/FaqList"),
        meta: {
            pageTitle: "FAQ List",
            resource: "FAQ",
            breadcrumb: [
                {
                    text: "faq",
                    active: true
                }
            ]
        }
    },
    {
        path: "/add/faq",
        name: "add-faq",
        component: () => import("@/views/faqs/add/FaqAdd"),
        meta: {
            pageTitle: "FAQ Add",
            resource: "FAQ",
            breadcrumb: [
                {
                    text: "faq",
                    to: "/faq-list"
                },
                {
                    text: "Add",
                    active: true
                }
            ]
        }
    },
    {
        path: "/edit/faqs/:id",
        name: "edit-faq",
        component: () => import("@/views/faqs/edit/FaqEdit"),
        meta: {
            pageTitle: "FAQ Edit",
            resource: "FAQ",
            breadcrumb: [
                {
                    text: "faq",
                    to: "/faq-list"
                },
                {
                    text: "Edit",
                    active: true
                }
            ]
        }
    },
    {
        path: "/view/faqs/:id",
        name: "view-faq",
        component: () => import("@/views/faqs/view/FaqView"),
        meta: {
            pageTitle: "FAQ View",
            resource: "FAQ",
            breadcrumb: [
                {
                    text: "faq",
                    to: "/faq-list"
                },
                {
                    text: "View",
                    active: true
                }
            ]
        }
    },
    {
        path: "/individual-okrs",
        name: "individual-okrs",
        component: () => import("@/views/okrs/individual-okrs/list"),
        meta: {

            resource: "OLD My OKR",
            breadcrumb: [
                {
                    text: "OKR",
                },
                {
                    text: "My OKRs",
                },
                {
                    text: "List",
                    active: true
                }
            ]
        }
    },
    {
        path: "/team-okrs",
        name: "team-okrs",
        component: () => import("@/views/okrs/organization-okrs/list"),
        meta: {
            resource: "OLD Team OKR",
            breadcrumb: [
                {
                    text: "OKR",
                },
                {
                    text: "Team OKRs",
                },
                {
                    text: "List",
                    active: true
                }
            ]
        }
    },
    {
        path: "/org-chart",
        name: "org-chart",
        component: () => import("@/views/company/tree.vue"),
        meta: {
            resource: "Company Tree",
            breadcrumb: [
                {
                    text: "Company Tree",
                    active: true
                }
            ]
        }
    },
    {
        path: "/profile/:id",
        name: "employee-profile",
        component: () => import("@/views/feed/Community.vue"),
        // component: () => import("@/views/employee-profile/Profile.vue"),
        meta: {
            resource: "Profile",
            breadcrumb: [
                {
                    text: "Profile",
                    active: true
                }
            ]
        }
    },
    {
        path: '/chats',
        name: 'chats',
        component: () => import('@/views/chat/Chat.vue'),
        meta: {
            resource: "Chats",
            breadcrumb: [
                {
                    text: "Chat",
                    active: true
                }
            ],
            contentRenderer: 'sidebar-left',
            contentClass: 'chat-application',
        },
    },
];
